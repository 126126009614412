<template>
  <div class="flex between box widthCenter">
    <img :src="img" alt="" />
    <div>
      <h2>{{ title }}</h2>
      <p v-if="small">{{ small }}</p>
      <ul>
        <li><span v-if="text1">·</span>{{ text1 }}</li>
        <li><span v-if="text2">·</span>{{ text2 }}</li>
        <li><span v-if="text3">·</span>{{ text3 }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "leftImg",
  props: ["title", "small", "img", "text1", "text2", "text3"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.box {
    margin-top: 80px;
  h2{
    font-size: 40px;
  }
  div {
    font-weight: 600;
    font-size: 16px;
  }
  img{
    width: 600px;
  }
  p {
    color: #666;
    margin: 20px 0 30px 0;
  }
  ul {
    color: #666;
    li{
        display: flex;
        align-items: center;
    }
    span {
      font-size: 30px;
      font-weight: 600;
      margin-right: 5px;
    }
  }
}
</style>