<template>
  <div class="top" :style="{ 'background-image': 'url( ' + img + ')' }">
    <div class="nav widthCenter flex between">
      <div class="flex">
        <div class="flex leftBox">
          <!-- <img style="width: 60px" src="../assets/imgs/logo.png" alt="" />
          <div class="leftShu"></div>
          <h2 style="font-size: 20px; line-height: 1">创变营销</h2> -->
          <img src="../assets/imgs/topTitle.png" alt="" />
        </div>

        <ul class="flex tabs f_weight">
          <li><router-link to="/">首页</router-link></li>
          <li><router-link to="/put">投放营销</router-link></li>
          <li><router-link to="/email">邮箱营销</router-link></li>
          <li><router-link to="/media">新媒体营销</router-link></li>
        </ul>
      </div>
      <div v-if="isLogin" class="flex" style="align-items: center">
        <router-link to="/background">
          <button type="button" class="login">进入后台</button>
        </router-link>
        <el-dropdown @command="handleCommand">
          <span
            class="el-dropdown-link avatar"
            @mouseover="wx = false"
            @mouseout="wx = false"
          >
            <img :src="userinfo.avatar" alt="" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div @mouseover="wx = true">
                <img v-show="wx" id="wx" src="../assets/imgs/wx.png" alt="" />
                <i class="el-icon-headset"></i>
                专属客服
              </div>
            </el-dropdown-item>
            <el-dropdown-item command="修改密码">
              <div @mouseout="wx = false">
                <i class="el-icon-edit"></i>
                <span>修改密码</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item command="退出登录">
              <div @mouseout="wx = false">
                <i class="el-icon-switch-button"></i>
                <span>退出登录</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="f_weight" v-else>
        <span @click="goLogin" class="pointer">注册</span>
        <span class="rightShu">|</span>
        <span @click="goLogin" class="pointer">登录</span>
      </div>
    </div>
    <div class="title">
      <h1>{{ title }}</h1>
      <p class="f_weight">
        {{ small }}
      </p>
      <el-button @click="goLogin" type="primary">立即使用</el-button>
    </div>
  </div>
</template>

<script>
import { loginout } from "../http/api";
export default {
  name: "headerBanner",
  props: ["title", "small", "img"],
  data() {
    return {
      userinfo: JSON.parse(localStorage.getItem("userinfo")) || {},
      isLogin: localStorage.getItem("token"),
      wx: false,
    };
  },
  methods: {
    goLogin() {
      this.$router.push({ name: "login" });
    },
    handleCommand(command) {
      if (command == "修改密码") {
        this.$router.push({
          name: "background",
          params:{
            index:5
          }
        });
      } else if (command == "退出登录") {
        loginout().then((res) => {
          if (res.code == 0) {
            localStorage.removeItem("token");
            localStorage.removeItem("userinfo");
            this.isLogin = false;
            this.$message.success({
              duration: 2000,
              message: "退出成功",
            });
            this.$router.replace({
              name: "Home",
            });
          } else {
            this.$message.error({
              duration: 2000,
              message: res.message,
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#wx {
  position: absolute;
  width: 120px;
  top: 0;
  left: -125px;
}
.avatar img {
  width: 40px !important;
  border-radius: 50%;
  margin-left: 20px;
}
.login {
  background: rgb(194, 176, 223);
  background: linear-gradient(
    270deg,
    rgba(194, 176, 223, 1) 0%,
    rgb(84, 105, 209) 45%
  );
  border-radius: 20px;
  color: #fff;
  height: 30px;
  padding: 0 20px;
  font-weight: 600;
}
.leftBox {
  img {
    width: 260px;
  }
  // align-items: flex-end;
}
.top {
  border-bottom: 1px solid #ddd;
  height: 500px;
  // background: url("@/assets/imgs/index/banner.png") no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .nav {
    // align-items: flex-end;
    padding-top: 10px;
    > div {
      // align-items: flex-end;
    }
    .leftShu {
      margin: 0 10px;
      width: 2px;
      height: 20px;
      background: #000;
    }
    .rightShu {
      margin: 0 10px;
    }
    .tabs {
      margin-left: 100px;

      li {
        margin: 0 20px;
      }
    }
  }
  .title {
    width: 1200px;
    margin: 100px auto;
    h1 {
      font-size: 46px;
    }
    p {
      font-size: 18px;
      margin: 50px 0;
    }
  }
}
</style>>

